import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import useForm from 'react-hook-form';
import {
  Typography,
  Dialog, DialogTitle, DialogContent,
  DialogContentText, TextField, DialogActions, Button,
  FormControl, InputLabel, Select, MenuItem, FormHelperText, Input
} from '@material-ui/core';

import RessourceToolbar from 'components/RessourceToolBar';
import RessourceTable from 'components/RessourceTable';
import Loader from 'components/Loader';
import { getUsers, getUserRoles, editUser, deleteUser, addUser } from 'services/users';
import Message from 'components/Message/Message';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

const actionTitleByType = {
  edit: 'Modifier',
  add: 'Ajouter',
  delete: 'Supprimer'
};

const UserList = () => {
  const classes = useStyles();

  const [addCollectorOpen, setAddCollectorOpen] = useState(false);
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [showFiltered, setShowFiltered] = useState(false);
  const [roles, setRoles] = useState([]);
  const [searchVal, setSearchVal] = useState('');
  const [role, setRole] = useState('');
  const [actionType, setActionType] = useState('add');
  const [roleError, setRoleError] = useState('');
  const [loading, setLoading] = useState(true);
  const [loaded, setLoaded] = useState(false);
  const [userId, setUserId] = useState(undefined);
  const [formError, setFormError] = useState('');
  const [userFormError, setUserFormError] = useState('');
  const { register, errors, handleSubmit, setValue, watch, reset } = useForm();

  const { firstName, lastName, mail, password } = watch();

  useEffect(() => {
    register({ name: 'firstName' });
    register({ name: 'lastName' });
    register({ name: 'mail' });
    register({ name: 'password' });
  }, [register]);

  const handleChange = (name, e) => {
    e.persist();
    setValue(name, e.target.value);
  };

  const fetchUsers = async () => {
    try {
      const userData = await getUsers();
      setUsers(userData);
      const rolesData = await getUserRoles();
      setRoles(rolesData)
      setLoaded(true);
      setLoading(false);
    } catch (error) {
      setLoaded(true);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!loaded) {
      fetchUsers();
    }
  }, [loaded]);

  const handleUser = async(data) => {
    setUserFormError('');
    try {
      if (role === '') {
        setRoleError('Ce champ est requis');
        return;
      }
      setRoleError('');
      setAddCollectorOpen(false);
      setLoading(true);
      switch (actionType) {
        case 'add':
          await addUser({ ...data, role });
          break;
        case 'edit':
          if (userId) {
            await editUser(userId, { ...data, role });
          }
          break;
        default:
          break;
      }
      const userData = await getUsers();
      setUsers(userData);
      setLoading(false);
    } catch (error) {
      setAddCollectorOpen(true);
      setUserFormError(error.message);
      setLoading(false);
    }
  };

  const deleteUserById = async (userId) => {
    setFormError('');
    setLoading(true);
    if (userId) {
      try {
        await deleteUser(userId);
        const userData = await getUsers();
        setUsers(userData);
        setLoading(false);
      } catch (error) {
        setFormError(error.message);
        setLoading(false);
      }
    }
  }

  const fields = [
    {
      name: 'Email',
      display: (u, c) => <p>{u.mail}</p>
    },
    {
      name: 'Role',
      display: (u, c) => <p>{u.role.item2}</p>
    },
    {
      name: 'Action',
      display: u => (
        <>
          {/* <Button
            color="primary"
            onClick={async () => {
              reset();
              setUserId(u.id);
              setValue('firstName', u.firstName);
              setValue('lastName', u.lastName);
              setValue('mail', u.mail);
              setActionType('edit');
              setRole(u.role.item1);
              setAddCollectorOpen(true);
            }}
            size="small"
            variant="text"
          >
          Modifier
          </Button> */}
          <Button
            color="primary"
            onClick={async () => {
              const ok = window.confirm(`Etes-vous certain de vouloir supprimer cet utilisateur: ${u.firstName} ${u.lastName}?`);
              if (ok) {
                deleteUserById(u.id);
              }
            }}
            size="small"
            variant="contained"
          >
          Supprimer
          </Button>
        </>
      )
    }
  ];

  const showAddUser = () => {
    reset();
    setUserFormError('');
    setUserId(undefined);
    setRole('');
    setValue('firstName', '');
    setValue('lastName', '');
    setValue('mail', '');
    setValue('password', '');
    setActionType('add');
    setAddCollectorOpen(!addCollectorOpen);
  }

  const handleSearch = e => {
    if (searchVal.length > 0) {
      const fUsers = users.filter(
        u =>
          u.mail.toLowerCase().indexOf(searchVal) !== -1 ||
          u.firstName.toLowerCase().indexOf(searchVal) !== -1 ||
          u.lastName.toLowerCase().indexOf(searchVal) !== -1
      );
      setFilteredUsers(fUsers);
      setShowFiltered(true);
    } else {
      setShowFiltered(false);
    }
  };

  return (
    <div className={classes.root}>
      {formError !== '' && <Message
        intent="DANGER"
        message={formError}
      />}
      <Dialog
        aria-labelledby="form-dialog-title"
        onClose={showAddUser}
        open={addCollectorOpen}
      >
        <form onSubmit={handleSubmit(handleUser)}>
          <DialogTitle id="form-dialog-title">{actionTitleByType[actionType]} un utilisateur</DialogTitle>
          <DialogContent>
            {userFormError !== '' && <Message
              intent="DANGER"
              message={userFormError}
            />}
            <DialogContentText />
            <TextField
              autoFocus
              error={errors.firstName ? true : false}
              fullWidth
              helperText={
                errors.firstName && errors.firstName.message
              }
              inputRef={register({
                required: 'Ce champ est requis'
              })}
              label="Nom"
              margin="dense"
              name="firstName"
              onChange={e => handleChange('firstName', e)}
              type="text"
              value={firstName}
            />
            <TextField
              error={errors.lastName ? true : false}
              fullWidth
              helperText={
                errors.lastName && errors.lastName.message
              }
              inputRef={register({
                required: 'Ce champ est requis'
              })}
              label="Prénom"
              margin="dense"
              name="lastName"
              onChange={e => handleChange('lastName', e)}
              type="text"
              value={lastName}
            />
            <FormControl
              error={roleError !== '' ? true : false}
              fullWidth
              name="role"
            >
              <InputLabel
                htmlFor="age-simple"
              >Rôle</InputLabel>
              <Select
                fullWidth
                onChange={(e) => setRole(e.target.value)}
                value={role}
              >
                {roles.map(r => (<MenuItem
                  key={r.id}
                  selected={r.id === role}
                  value={r.id}
                >
                  {r.name}
                </MenuItem>))}
              </Select>
              {roleError !== '' && <FormHelperText>{roleError}</FormHelperText>}
            </FormControl>
            <TextField
              error={errors.mail ? true : false}
              fullWidth
              helperText={
                errors.mail && errors.mail.message
              }
              inputRef={register({
                required: 'Ce champ est requis',
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: 'Email invalide'
                }
              })}
              label="Email"
              margin="dense"
              name="mail"
              onChange={e => handleChange('mail', e)}
              type="email"
              value={mail}
            />
            {actionType === 'add' && (
              <TextField
                error={errors.password ? true : false}
                fullWidth
                helperText={
                  errors.password && errors.password.message
                }
                inputRef={register({
                  required: 'Ce champ est requis',
                  minLength: {
                    value: 5,
                    message: 'Minimum 5 charactères'
                  }
                })}
                label="Mot de passe"
                margin="dense"
                name="password"
                onChange={e => handleChange('password', e)}
                type="password"
                value={password}
              />
            )}
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              onClick={showAddUser}
            >
            Annuler
            </Button>
            <Button
              color="primary"
              type="submit"
              variant="contained"
            >
              {actionTitleByType[actionType]}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <Typography variant="h1">Utilisateurs</Typography>
      <RessourceToolbar
        addTitle="Ajouter un utilisateur"
        onAdd={showAddUser}
        onSearch={handleSearch}
        searchTitle="Rechercher un utilisateur"
        searchVal={searchVal}
        setSearchVal={setSearchVal}
      />
      {loading ? (
        <Loader />
      ) : (
        <div className={classes.content}>
          <RessourceTable
            fields={fields}
            ressources={showFiltered ? filteredUsers : users}
          />
        </div>
      )}
    </div>
  );
};

export default UserList;
